import * as React from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Link } from "gatsby";
import * as styles from "./Slide.module.scss";
import { getCategoryName } from "../../pages";

interface SlideProps {
  category: string;
  thumbnail: IGatsbyImageData;
  title: string;
  to: string;
}

function Slide({ category, thumbnail, title, to }: SlideProps): JSX.Element {
  return (
    <Link className={styles.link} to={to}>
      <GatsbyImage alt={title} className={styles.img} image={thumbnail} />
      <div className={styles.caption}>
        <p className={styles.title}>{title}</p>
        <p className={styles.category}>{getCategoryName(category)}</p>
      </div>
    </Link>
  );
}

export default Slide;
