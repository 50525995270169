import * as React from "react";
import { useRef } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import "@splidejs/splide/dist/css/splide.min.css";

import Slide from "./Slide";
import Button from "../Button/Button";

import * as styles from "./Slider.module.scss";

function Slider({
  projects,
}: {
  projects: Array<{
    frontmatter: {
      category: string;
      slug: string;
      thumbnail: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
      title: string;
    };
    id: string;
  }>;
}): JSX.Element {
  const sliderRef = useRef<Splide>(null);
  return (
    <div className={styles.sliderWrapper} data-breeze="slide-up">
      <Button
        className={styles.arrow}
        onClick={(): void => {
          sliderRef.current?.splide?.go("<");
        }}
        type="button"
        variant="link"
      >
        &larr;
      </Button>
      <Splide
        ref={sliderRef}
        className={styles.slider}
        options={{
          type: "loop",
          perPage: 3,
          breakpoints: {
            576: {
              perPage: 1,
            },
            768: {
              perPage: 2,
            },
          },
          perMove: 3,
          gap: "1rem",
          arrows: false,
          pagination: false,
          waitForTransition: false,
          slideFocus: false,
        }}
      >
        {projects.map((project) => {
          return (
            <SplideSlide key={project.id}>
              <Slide
                category={project.frontmatter.category}
                thumbnail={
                  project.frontmatter.thumbnail.childImageSharp.gatsbyImageData
                }
                title={project.frontmatter.title}
                to={`/project/${project.frontmatter.slug}`}
              />
            </SplideSlide>
          );
        })}
      </Splide>
      <Button
        className={styles.arrow}
        onClick={(): void => {
          sliderRef.current?.splide?.go(">");
        }}
        type="button"
        variant="link"
      >
        &rarr;
      </Button>
    </div>
  );
}

export default Slider;
