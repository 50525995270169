import * as React from "react";
import { Link } from "gatsby";

import * as styles from "./Breadcrumb.module.scss";

function Breadcrumb({
  items,
  ...props
}: React.ComponentProps<"nav"> & {
  items: Array<{
    title: string;
    to: string;
  }>;
}): JSX.Element {
  return (
    <nav {...props} aria-label="breadcrumb">
      <ol className={styles.breadcrumb}>
        {items.map((item, i, array) => {
          const breadcrumbItemClassName = new Set([styles.breadcrumbItem]);
          if (i === array.length - 1) {
            breadcrumbItemClassName.add(styles.active);
          }
          return (
            <li
              key={item.title}
              className={Array.from(breadcrumbItemClassName).join(" ")}
            >
              <Link className={styles.breadcrumbLink} to={item.to}>
                {item.title}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

export default Breadcrumb;
