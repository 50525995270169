import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { IGatsbyImageData, getSrc } from "gatsby-plugin-image";

import { getCategoryName } from "../index";
import Layout from "../../components/Layout/Layout";
import Head, { Meta } from "../../components/Head/Head";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Paragraph from "../../components/Paragraph/Paragraph";
import Slider from "../../components/Slider/Slider";

import * as styles from "./{mdx.slug}.module.scss";

interface QueryTypes {
  project: {
    body: string;
    frontmatter: {
      category: string;
      description: string;
      featured_thumbnail: {
        childImageSharp: IGatsbyImageData;
      };
      thumbnail: {
        childImageSharp: IGatsbyImageData;
      };
      title: string;
    };
    id: string;
  };
  projects: {
    nodes: Array<{
      frontmatter: {
        category: string;
        slug: string;
        thumbnail: {
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData;
          };
        };
        title: string;
      };
      id: string;
    }>;
  };
}

function Project({ data }: PageProps<QueryTypes>): JSX.Element {
  let thumbnail: string | undefined;
  if (data.project.frontmatter.featured_thumbnail) {
    thumbnail = getSrc(
      data.project.frontmatter.featured_thumbnail.childImageSharp,
    );
  } else if (data.project.frontmatter.thumbnail) {
    thumbnail = getSrc(data.project.frontmatter.thumbnail.childImageSharp);
  }
  const meta: Meta = [];
  if (thumbnail) {
    meta.push({
      name: "og:image",
      content: thumbnail,
    });
    meta.push({
      name: "og:image:alt",
      content: data.project.frontmatter.title,
    });
    meta.push({
      name: "twitter:image",
      content: thumbnail,
    });
    meta.push({
      name: "twitter:image:alt",
      content: data.project.frontmatter.title,
    });
  }
  return (
    <Layout floatingBackToTopButton>
      <Head
        description={data.project.frontmatter.description}
        meta={meta}
        title={data.project.frontmatter.title}
      />
      <div className={styles.container}>
        <Breadcrumb
          className={styles.breadcrumb}
          items={[
            {
              title: "home",
              to: "/",
            },
            {
              title: "discover my projects",
              to: "/#discover",
            },
            {
              title: getCategoryName(data.project.frontmatter.category),
              to: `/#${data.project.frontmatter.category}`,
            },
            { title: data.project.frontmatter.title, to: "." },
          ]}
        />
      </div>
      <article className={[styles.containerSm, styles.article].join(" ")}>
        <MDXRenderer style={{ marginBottom: 0 }}>
          {data.project.body}
        </MDXRenderer>
      </article>
      <div className={styles.container}>
        <Paragraph title="other projects" />
        <Slider
          projects={data.projects.nodes
            .filter((node) => node.id !== data.project.id)
            .map((node) => ({ node, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ node }) => node)}
        />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($id: String) {
    project: mdx(id: { eq: $id }) {
      body
      frontmatter {
        category
        description
        featured_thumbnail {
          childImageSharp {
            gatsbyImageData
          }
        }
        thumbnail {
          childImageSharp {
            gatsbyImageData
          }
        }
        title
      }
      id
    }
    projects: allMdx {
      nodes {
        frontmatter {
          category
          slug
          thumbnail {
            childImageSharp {
              gatsbyImageData
            }
          }
          title
        }
        id
      }
    }
  }
`;

export default Project;
